body {
  background-color: #f4f6fa;
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: Inter;
    src:url('dist/font/Inter-Regular.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* system user table */
.badge-active {
  background-color: #ebf8ed;
  color: #1bb03f;
  border-radius: 10px;
}

.badge-inactive {
  background-color: #e8f1fa;
  color: #1976d2;
  border-radius: 10px;
}

.badge-error {
  background-color: #fae9e8;
  color: #d32f2f;
  border-radius: 10px;
}

.btnIcon-transparent {
  background-color: transparent;
  border: none;
}
.btnIconHover-prime:hover {
  color: #1976d2;
}
.btnIconHover-error:hover {
  color: #d32f2f;
}

#loading{
  width:100%;
  height:100%;
  left:0;
  position:fixed;
  z-index:9999999999999;
  background: url("../public/dist/img/loading.gif") no-repeat center center rgba(0,0,0,0.25);
}

#loading-table{
  background: url("../public/dist/img/loading.gif") no-repeat center center rgba(0,0,0,0.05);
  background-size: contain;
}

#btn-sidebar-custom {
  background-color: none;
  border-color: none;
}

#btn-sidebar-custom:hover {
  background-color: none;
  border-color: none;
}

#btn-sidebar-custom:active, #btn-sidebar-custom.active {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}

/* #btn-sidebar-custom:focus, #btn-sidebar-custom.focus {
  color: #fff;
  background-color: none;
  border-color: none;
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
} */
.custom-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.custom-card-header:first-child {
  border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@media screen and (max-width: 600px){
	#modal-mobile{
		width: 90%;
	}
}

@media screen and (max-width: 483px){
  .item-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.7rem;
  }
}